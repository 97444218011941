@import './fonts/fonts.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

.line-underline-active {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
  background-size: 100% 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size .5s ease-in-out;
}

.line-underline-active-black {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#000, #000);
  background-size: 100% 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size .5s ease-in-out;
}

.line-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
  background-size: 0 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size .5s ease-in-out;
}

.line-underline-black {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#000, #000);
  background-size: 0 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size .5s ease-in-out;
}

.line-underline:hover {
  background-size: 100% 3px;
  background-position: 0 100%
}

.line-underline-black:hover {
  background-size: 100% 3px;
  background-position: 0 100%
}

.Toastify__progress-bar {
  background: #262150 !important;
}

.slick-dots li.slick-active button:before {
  color: #c79744 !important;
}

@layer utilities {
  @variants responsive {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}