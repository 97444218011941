@font-face {
  font-family: "Kiona";
  src: url("./Kiona-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Custom Font: Adam-Bold */

@font-face {
  font-family: 'Adam-Bold';
  src: url('./Adam-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Custom Font: Adam-Light */
@font-face {
  font-family: 'Adam-Light';
  src: url('./Adam-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Custom Font: Adam-Medium */
@font-face {
  font-family: 'Adam';
  src: url('./Adam-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('./Avenir-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Bold';
  src: url('./Avenir-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Light';
  src: url('./Avenir-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}